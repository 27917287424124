<script>
import axios from "axios";
import { authHeader } from "../../../../helpers/auth-header";
import ImageFile from '@/components/buttons/image-file'
import File_download from '@/components/buttons/file-download';
import { mapActions } from 'vuex';
export default {
  components: {
    ImageFile,
    File_download
  },
  data() {
        return {
        }
    },
    async created() {
    }, 
    props: {
        tenancy_data: {
            type: Object,
            required: true
        },
    },
    methods: {
        ...mapActions('modal', ['openConfirmModal', 'closeConfirmModal', 'openMessageModal']),
        
        deleteImg(e) {
            if (e.url != undefined || e.url != null) {
                this.openConfirmModal({"message" : "Confirm delete file (" + e.fileName + ")", "callback" : async () => {
                    const result = await axios.delete(`${process.env.VUE_APP_APIURL}/${e.url}${e.id}`, {
                        headers: authHeader(),
                    }).catch( function (error) {
                        console.log("fail:" + error.message);
                    });

                    if (result.data.code === 'success') {
                        this.closeConfirmModal();
                        this.openMessageModal({message: "The record has been deleted", redirect:'refresh'});
                    } else if (result.data.code === 'file_not_found') {
                        this.closeConfirmModal();
                        this.openMessageModal({message: "File not found! Please try again"});
                    } else if (result.data.code === 'permission_denied') {
                        this.closeConfirmModal();
                        this.openMessageModal({message: "Permission Denied!"});
                    }
                }});
            }
            // console.log(e);
        },
        deleteAmenityPic(e) {
            // console.log(e);
            // this.addError('test');
            e.url = 'tenancy/delete-amenity/';
            this.deleteImg(e);
        },
        deleteKyc(e) {
            e.url = 'tenancy/delete-kyc/';
            this.deleteImg(e);
        },
        deleteStamp() {
            // let url = {url: "tenancy/delete-stamp-doc/", id: this.tenancy_data.stamp_doc.id };
            this.openConfirmModal({"message" : "Confirm delete file (" + this.tenancy_data.stamp_doc.file_name + ")", "callback" : async () => {
                const result = await axios.delete(`${process.env.VUE_APP_APIURL}/tenancy/delete-stamp-doc/${this.tenancy_data.stamp_doc.id}`, {
                    headers: authHeader(),
                }).catch( function (error) {
                    console.log("fail:" + error.message);
                });

                if (result.data.code === 'success') {
                    this.closeConfirmModal();
                    this.openMessageModal({message: "The record has been deleted", redirect:'refresh'});
                } else if (result.data.code === 'file_not_found') {
                    this.closeConfirmModal();
                    this.openMessageModal({message: "File Not Found! Please Try Again"});
                } else if (result.data.code === 'permission_denied') {
                    this.closeConfirmModal();
                    this.openMessageModal({message: "Permission Denied!"});
                }
            }});
        },
        async downloadFile() {
            if (this.tenancy_data.stamp_doc) {

                let data = {"order_item_id": this.tenancy_data.id};
                await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/download-stamp-doc/${this.tenancy_data.stamp_doc.id}`, {
                    params: data,
                    headers: authHeader(),
                    responseType: 'blob',
                }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', this.tenancy_data.stamp_doc.file_name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }).catch(function (error) {
                    alert("Fail to save:" + error.message);
                });
            }
        },
    }
}
</script>

<template>
    <div>
        <div class="row mt-2">
            <div class="col-12" v-if="tenancy_data.amenity_image && tenancy_data.amenity_image.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Amenity Attachments</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6 mb-2" v-for="img in tenancy_data.amenity_image" :key="img.id">
                        <ImageFile :img_url="img.image" :file_name="img.label" :file_id="parseInt(img.id)" @deleteImg="deleteAmenityPic" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" v-if="tenancy_data.tenant_personal_kyc && tenancy_data.tenant_personal_kyc.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Tenant - Personal Attachments</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.tenant_personal_kyc" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" v-if="tenancy_data.tenant_business_kyc && tenancy_data.tenant_business_kyc.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Tenant - Business Attachments</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.tenant_business_kyc" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" v-if="tenancy_data.landlord_personal_kyc && tenancy_data.landlord_personal_kyc.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Landlord - Personal Attachments</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.landlord_personal_kyc" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4" v-if="tenancy_data.landlord_business_kyc && tenancy_data.landlord_business_kyc.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Landlord - Business Attachments</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.landlord_business_kyc" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4" v-if="tenancy_data.landlord_signature && tenancy_data.landlord_signature.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Landlord Signature</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.landlord_signature" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4" v-if="tenancy_data.tenant_signature && tenancy_data.tenant_signature.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Tenant Signature</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.tenant_signature" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" @deleteImg="deleteKyc" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4" v-if="tenancy_data.tenant_verification && tenancy_data.tenant_verification.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Tenant Verification</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.tenant_verification" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4" v-if="tenancy_data.landlord_verification && tenancy_data.landlord_verification.length > 0">
                <h4 class="subTitle-font mr-2 mb-2">Landlord Verification</h4>
                <div class="row">
                    <div class="col-12 col-md-3 col-sm-6" v-for="img in tenancy_data.landlord_verification" :key="img.id">
                        <ImageFile :img_url="img.data" :file_name="img.file_name" :file_id="parseInt(img.id)" :hideDeleteButton="['pending_tenant_signature', 'pending_landlord_signature', 'pending_submit', 'pending_stamping', 'completed', 'expired'].includes(tenancy_data.status)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-sm-6 mt-2"  v-if="tenancy_data.stamp_doc != null">
                <h4 class="subTitle-font mr-2 mb-2">Stamping Document</h4>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <File_download :link="tenancy_data.stamp_doc.path" :file_name="tenancy_data.stamp_doc.file_name" @download="downloadFile" :hideDeleteButton="true" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>