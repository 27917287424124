<script>
import BasicTable from "@/components/listings/basic-table";
import axios from "axios";
import { authHeader } from "../../../../helpers/auth-header";
// import { badgeColor, statusLabel } from "@/util/mapfunction.js";
import StatusBadge from "@/components/buttons/tenancy-status";

export default {
	props: ["id", "order_item_id"],
	components: {
		BasicTable,
		StatusBadge
	},
	data() {
		return {
			ctcFields: [
				{ key: "order_display_id", label: 'ID' },
				{ key: "status", label: 'Status' },
				{ key: "created_at", label: 'Request Date' },
				{ key: "details", label: 'Shipping Details' },
			],
			perPage: 10,
			ctc: [],
			totalRecords: 1,
			current_page: 1,
			ctc_loading: false,
			badgeKey: 0,
		};
	},
	async created() {
		this.loadData(this.current_page)
	},
	methods: {
		async loadData(page) {
			this.ctc_loading = true;
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/ctc/get-all/tenancy/${this.$route.params.id}`, {
				headers: authHeader(),
				params: {
					"length": this.perPage,
					"page": page
				}
			}).catch(function (error) {
				this.ctc_loading = false;
				alert("Ctc fetch error:" + error.message);
			});

			if (result.data.code === 'success') {
				// const {data} = result.data.data.data.ctc;
				// const ctcData = result.data.data.data.data[0]?.ctc || [];
				const ctcData = result.data.data.ctcs.data;
				console.log('bbbb', result.data.data.ctcs.data)

				this.ctc = [];
				ctcData.forEach(element => {
					let item = {
						"order_id": element.order_id,
						"order_display_id": element.order_display_id,
						"status": element.status,
						"created_at": element.created_at,
						"courier_company": element.courier_company,
						"tracking_no": element.shipping_tracking_no,
					}
					this.ctc.push(item);
				});
				this.badgeKey += 1;
				// this.totalRecords = result.data.data.total_records;
				this.totalRecords = result.data.data.ctcs.total;
			}
			this.ctc_loading = false;
		},
		async changePage(pg) {
			await this.loadData(pg);
		},
		// badgeClass(val) {
		//   return badgeColor("ctc", val);
		// },
		// badgeText(val) {
		//   return statusLabel(val);
		// },
	}
};
</script>

<template>
	<div class="card">
		<div class="card-body">
			<BasicTable :perPage="perPage" :fields="ctcFields" :items="ctc" :isBusy="ctc_loading"
				:totalRows="totalRecords" @page-click="changePage">

				<template v-slot:cell(status)="row">
					<StatusBadge :status="row.value" type="ctc" v-if="row.value != ''" :key="badgeKey" />
				</template>

				<template v-slot:cell(order_display_id)="row">
					<router-link :to="{ name: 'Ctc View', params: { id: row.item.order_id } }" class="ml-1 mr-1">
						{{ row.item.order_display_id }}
					</router-link>
				</template>

				<template v-slot:cell(details)="row">
					<p class="mb-0">Courier Company: {{ row.item.courier_company ? row.item.courier_company : '-' }}</p>
					<p class="mb-0">Tracking No.: {{ row.item.tracking_no ? row.item.tracking_no : '-' }}</p>
				</template>
			</BasicTable>
		</div>
	</div>
</template>
